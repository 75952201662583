import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Components
import { FiltersPanelBlock } from '../../components';
import { Checkbox } from '../../components/forms';

// Slices
import {
	filtersSelector,
	toggleModel,
	updateFilters,
	selectAll,
	selectNone,
} from '../../slices/calculator/filters';
import { modelsSelector } from '../../slices/calculator/models';
import { viewSelector, toggleFilters } from '../../slices/calculator/view';

// Types
import {
	FiltersArchitectures,
	FiltersModel,
	FiltersSliceState,
} from '../../types';
import { useURLFilters } from '../../hooks';

const SwitchPanel = styled.div`
	flex-basis: 40px;
	flex-shrink: 0;
	flex-grow: 0;
	padding: 20px;
	cursor: pointer;
`;

const Panel = styled.div`
	flex-basis: 300px;
	flex-shrink: 0;
	flex-grow: 0;
	padding: 20px;
`;

const PanelTitle = styled.h5`
	font-size: 20px;
	display: flex;
	justify-content: space-between;
`;

const ModelsList = styled.div`
	width: 90%;
	padding: 0px;
	padding-top: 0px;
	margin-bottom: 20px;
`;

const PlatformTitle = styled.span`
	display: block;
	margin-top: 20px;
`;

const ModelsListButton = styled.button`
	margin-right: 15px;
`;

const ModelsListActionsWrapper = styled.div`
	margin-bottom: 10px;
`;

const ModelsListWrapper = styled.div`
	min-height: 0;
	overflow-y: scroll;
`;

const FiltersPanel = () => {
	const dispatch = useDispatch();
	const filters = useSelector(filtersSelector);
	const models = useSelector(modelsSelector);
	const view = useSelector(viewSelector);
	const [searchInput, setSearchInput] = useState('');
	const { encodeFilters, encodeSelectionState, pushHistory } = useURLFilters();

	// Handle inputs change
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// If block is set the change is in a children object of filters slice
		const block: string | undefined = e.target.dataset.block;
		// The name of the field
		const name: string = e.target.name;
		// The value of the checkbox
		const value: boolean = e.target.checked;

		// If block is set we change a sub-object
		if (block && name) {
			/*
			 ** Cloning current sub-object
			 ** Using JSON to clone an entity without knowing its type (object or array)
			 */
			const actual: FiltersArchitectures | string[] = JSON.parse(
				JSON.stringify(filters[block]),
			);
			let res: FiltersSliceState;
			if (Array.isArray(actual)) {
				if (!value && actual.includes(name))
					actual.splice(actual.indexOf(name), 1);
				else if (value && !actual.includes(name)) actual.push(name);
			} else if (typeof actual === 'object') {
				actual[name] = value;
			}
			res = Object.assign({}, filters, { [block]: actual });
			pushHistory(encodeFilters(res), 'filters');
			dispatch(updateFilters(res));
		} else {
			const res: FiltersSliceState = Object.assign({}, filters, {
				[name]: !filters[name],
			});
			pushHistory(encodeFilters(res), 'filters');
			dispatch(updateFilters(res));
		}
	};

	const capitalizeStyle = (name: string) =>
		name.length > 3
			? name.charAt(0).toUpperCase() + name.slice(1)
			: name.toUpperCase();

	const handleCheckbox = (item: FiltersModel) => {
		dispatch(toggleModel(item));
		// Pushing Unselected items in URL to save state
		let cloned: FiltersModel[] = JSON.parse(JSON.stringify(filters.models));
		cloned[filters.models.indexOf(item)].selected =
			!cloned[filters.models.indexOf(item)].selected;
		cloned = cloned.filter((item) => !item.selected);
		const uncheckedLabels: string[] = cloned.map((item) => item.modelUid);
		pushHistory(encodeSelectionState(uncheckedLabels), 'selection');
	};

	const ModelCheckbox = (item: FiltersModel) => (
		<Checkbox
			key={item.modelUid}
			name={item.modelUid}
			block="models"
			onChange={() => {
				handleCheckbox(item);
			}}
			checked={item.selected}
			id={`models-${item.modelUid}-filter`}
			label={item.modelName}
		/>
	);

	const ModelPlatformCheckbox = (name: string) => (
		<Checkbox
			name={name}
			block="platforms"
			onChange={handleChange}
			checked={filters.platforms.includes(name)}
			id={'platforms-' + name + '-filter'}
			key={'platforms-' + name + '-filter'}
			label={capitalizeStyle(name)}
		/>
	);

	const ModelPerformanceClassCheckbox = (name: string) => (
		<Checkbox
			name={name}
			block="performanceClasses"
			onChange={handleChange}
			checked={filters.performanceClasses.includes(name)}
			id={'performance-class-' + name + '-filter'}
			key={'performance-class-' + name + '-filter'}
			label={capitalizeStyle(name)}
		/>
	);

	// Render models Checboxes
	const renderModels = () => {
		if (models.loading) return <p>Loading ...</p>;
		if (models.errors) return <p>Errors</p>;

		let selectedModels = filters.models;

		// Filtering by search bar first if it is set
		if (searchInput.length) {
			selectedModels = selectedModels.filter((item) =>
				item.modelUid.toLowerCase().includes(searchInput.toLowerCase()),
			);
		}

		// const qumuloSortOrder = [
		//     'C-72T',
		//     'C-72T-HE',
		//     'C-168T',
		//     'C-168T-HE',
		//     'K-144T',
		//     'K-144T-HE',
		//     'K-168T',
		//     'K-168T-HE',
		//     'P-23T',
		//     'P-92T',
		//     'P-184T',
		//     'P-368T',
		//     'QC24',
		//     'QC24-HE',
		//     'QC40',
		//     'QC40-HE',
		//     'QC104',
		//     'QC104-HE',
		//     'QC208',
		//     'QC208-HE',
		//     'QC260',
		//     'QC260-HE',
		//     'QC360',
		// ];

		// const hpeSortOrder = [
		//     'HPE-36T-Gen10',
		//     'HPE-90T',
		//     'HPE-90T-Gen10',
		//     'HPE-180T',
		//     'HPE-192T-Gen10',
		//     'HPE-288T',
		//     'HPE-336T-Gen10',
		// ];

		let platformsModels: JSX.Element[] = [];
		filters.platforms.forEach((platform) => {
			platformsModels.push(
				<PlatformTitle
					key={'platform-title-' + platform}
					className="platform-title"
				>
					{capitalizeStyle(platform)} Models
				</PlatformTitle>,
			);
			const modelsRelated = selectedModels
				.filter((item) => item.platform === platform)
				.sort();
			modelsRelated.forEach((item) => {
				platformsModels.push(ModelCheckbox(item));
			});
		});

		return (
			<div>
				{platformsModels}
				{!selectedModels.length && 'No matching models'}
			</div>
		);
	};

	const platformsItems = filters.availablePlatforms.map((name) =>
		ModelPlatformCheckbox(name),
	);

	const performanceClassesItems = filters.availablePerformanceClasses.map(
		(name) => ModelPerformanceClassCheckbox(name),
	);

	if (view.showFilters) {
		return (
			<Panel
				className={`filters-panel ${view.showFilters ? 'active' : ''}`}
				id="filters-panel"
			>
				<PanelTitle>
					Filters
					<button
						className="btn btn-link btn-secondary close-filters-button"
						onClick={() => dispatch(toggleFilters())}
					>
						<i className="fa fa-chevron-left"></i>
					</button>
				</PanelTitle>
				<FiltersPanelBlock title="Platforms" noBorder={true}>
					{platformsItems}
				</FiltersPanelBlock>
				<FiltersPanelBlock title="Performance Class">
					{performanceClassesItems}
				</FiltersPanelBlock>
				<FiltersPanelBlock title="Architectures">
					<Checkbox
						name="allNVMe"
						block="architectures"
						onChange={handleChange}
						checked={filters.architectures.allNVMe}
						id="architectures-nvme-filter"
						label="All NVMe"
					/>
					<Checkbox
						name="hybrid"
						block="architectures"
						onChange={handleChange}
						checked={filters.architectures.hybrid}
						id="architectures-hybrid-filter"
						label="Hybrid"
					/>
				</FiltersPanelBlock>
				<FiltersPanelBlock title="Models">
					<div className="input-group model-search-wrapper">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i className="fa fa-search search-icon" />
							</span>
						</div>
						<input
							type="text"
							className="form-control model-search-input"
							aria-label="Search models"
							value={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
							style={{ textAlign: 'left' }}
						/>
					</div>
					<ModelsListActionsWrapper>
						<ModelsListButton
							className="btn btn-link no-padding underlined light"
							onClick={() => {
								dispatch(selectAll());
							}}
						>
							Select All
						</ModelsListButton>
						<ModelsListButton
							className="btn btn-link no-padding underlined light"
							onClick={() => {
								dispatch(selectNone());
							}}
						>
							Select None
						</ModelsListButton>
					</ModelsListActionsWrapper>
				</FiltersPanelBlock>
				<ModelsListWrapper>
					<ModelsList className="models-list">{renderModels()}</ModelsList>
				</ModelsListWrapper>
				<FiltersPanelBlock title="">
					<Checkbox
						name="highEndurance"
						onChange={handleChange}
						checked={filters.highEndurance}
						id="high-endurance-filter"
						label="Include High Endurance & Large Cache"
					/>
					<Checkbox
						name="limitedAvailability"
						onChange={handleChange}
						checked={filters.limitedAvailability}
						id="limited-availability-filter"
						label="Include Discontinued"
					/>
				</FiltersPanelBlock>
			</Panel>
		);
	} else {
		return (
			<SwitchPanel
				className="filters-switch-panel"
				id="filters-panel"
				data-cy="toggle-filters-btn"
				onClick={() => dispatch(toggleFilters())}
			>
				<OverlayTrigger
					placement={'right'}
					overlay={
						<Tooltip id="filters-panel-toggle-tooltip">
							Show filters panel
						</Tooltip>
					}
				>
					<i className="fas fa-filter blue"></i>
				</OverlayTrigger>
			</SwitchPanel>
		);
	}
};

export default FiltersPanel;
