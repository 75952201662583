import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModelsProperties } from '../../hooks';
import { ExistingItem, FormattedCluster } from '../../types';
import { formatNumber } from '../../utils/conversions';
import RowValue from '../RowValue';

// Charts
import CapacityChart from '../../containers/details/highcharts/capacity/CapacityChartWrapper';
import IOPSChart from '../../containers/details/highcharts/iops/IOPSChartWrapper';
import ThroughputChart from '../../containers/details/highcharts/throughput/ThroughputChartWrapper';
import { Checkbox } from '../forms';

// Slices
import { chartsSelector } from '../../slices/calculator/charts';

type PrintModalClusterTabProps = {
	data: FormattedCluster | null | undefined;
	existing?: ExistingItem[];
	selection?: string;
	mode: string;
	chartsToPrint?: boolean[];
	setChartsToPrint?: Dispatch<SetStateAction<boolean[]>>;
};

const PrintModalClusterTab = (props: PrintModalClusterTabProps) => {
	const modelProperties = useModelsProperties().getAllProperties();
	const existingProperties = useModelsProperties().getExistingProperties();
	const { data, mode, chartsToPrint, setChartsToPrint } = props;
	const selectedCluster = useSelector(chartsSelector).selectedCluster;
	const [chartProps, setChartProps] = useState({});

	useEffect(() => {
		if (selectedCluster) {
			setChartProps({
				model: selectedCluster.modelUid,
				nodes: selectedCluster.nodeCount.value as number,
				encoding: selectedCluster.encoding.value as number[],
				driveProtect: selectedCluster.driveOutageTolerance.value as number,
				nodeProtect: selectedCluster.nodeOutageTolerance.value as number,
			});
		}
	}, [selectedCluster]);

	const clusterLayout = (
		<>
			<div className="print-modal-data-wrapper">
				<div className="row data-row">
					<div className="data-cat col-md-4">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Capacity</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={modelProperties.usableCapacity.formattedLabel}
									data={
										typeof data?.usableCapacity.value === 'number'
											? formatNumber(data?.usableCapacity.value, 2)
											: ''
									}
									decimals={0}
									unitLabel={data?.usableCapacity.unit}
								/>
								<RowValue
									label={modelProperties.capacityScalesTo.formattedLabel}
									data={
										typeof data?.capacityScalesTo.value === 'number'
											? formatNumber(data?.capacityScalesTo.value, 2)
											: ''
									}
									decimals={0}
									unitLabel={data?.capacityScalesTo.unit}
								/>

								{mode === 'existing' && data?.usableCapacityAdded?.value && (
									<RowValue
										label={
											existingProperties['usableCapacityAdded']?.formattedLabel
										}
										data={
											typeof data?.usableCapacityAdded.value === 'number'
												? formatNumber(data?.usableCapacityAdded.value, 2)
												: ''
										}
										decimals={0}
										unitLabel={data?.usableCapacity.unit}
									/>
								)}
								<RowValue
									label={modelProperties.encoding.formattedLabel}
									data={`${data?.stripeWidth.value},${data?.dataElementsPerStripe.value}`}
									decimals={0}
								/>
								<RowValue
									label={modelProperties.capacity.formattedLabel}
									data={
										typeof data?.capacity.value === 'number'
											? formatNumber(data?.capacity.value, 2)
											: ''
									}
									decimals={0}
									unitLabel={data?.capacity.unit}
								/>

								<RowValue
									label={modelProperties.efficiency.formattedLabel}
									data={data?.efficiency.value}
									decimals={0}
									unitLabel={data?.efficiency.unit}
								/>
							</div>
						</div>
					</div>
					<div className="data-cat col-md-8">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Performance</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-6 data-block-item">
								<RowValue
									label={modelProperties.performanceClass.formattedLabel}
									data={data?.performanceClass.value}
									decimals={0}
									unitLabel={data?.performanceClass.unit}
								/>
								<RowValue
									label={modelProperties.cachedRead.formattedLabel}
									data={data?.cachedRead.value}
									decimals={0}
									unitLabel={data?.cachedRead.unit}
								/>
								<RowValue
									label={modelProperties.sustainedWrite.formattedLabel}
									data={data?.sustainedWrite.value}
									decimals={0}
									unitLabel={data?.sustainedWrite.unit}
								/>
								<RowValue
									label={modelProperties.uncachedRead.formattedLabel}
									data={data?.uncachedRead.value}
									decimals={0}
									unitLabel={data?.uncachedRead.unit}
								/>
								<RowValue
									label={
										modelProperties.singleStreamUncachedRead.formattedLabel
									}
									data={data?.singleStreamUncachedRead.value}
									decimals={0}
									unitLabel={data?.singleStreamUncachedRead.unit}
								/>
							</div>
							<div className="col-md-6 data-block-item">
								<RowValue
									label={modelProperties.burstWrite.formattedLabel}
									data={data?.burstWrite.value}
									decimals={0}
									unitLabel={data?.burstWrite.unit}
								/>
								<RowValue
									label={modelProperties.iops.formattedLabel}
									data={data?.iops.value}
									decimals={0}
									unitLabel={data?.iops.unit}
								/>
								<RowValue
									label={modelProperties.singleStreamWrite.formattedLabel}
									data={data?.singleStreamWrite.value}
									decimals={0}
									unitLabel={data?.singleStreamWrite.unit}
								/>
								<RowValue
									label={modelProperties.singleStreamCachedRead.formattedLabel}
									data={data?.singleStreamCachedRead.value}
									decimals={0}
									unitLabel={data?.singleStreamCachedRead.unit}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row data-row">
					<div className="data-cat col-md-4">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Nodes</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={modelProperties.nodeCount.formattedLabel}
									data={data?.nodeCount.value}
									decimals={0}
									unitLabel={data?.nodeCount.unit}
								/>
								{mode === 'existing' && (
									<RowValue
										label={existingProperties.nodeAdded?.formattedLabel}
										data={data?.nodeAdded?.value}
										decimals={0}
										unitLabel={data?.nodeCount.unit}
									/>
								)}
								{/*<RowValue*/}
								{/*	label={modelProperties.nodeCountScalesTo.formattedLabel}*/}
								{/*	data={data?.nodeCountScalesTo.value}*/}
								{/*	decimals={0}*/}
								{/*	unitLabel={data?.nodeCountScalesTo.unit}*/}
								{/*/>*/}
							</div>
						</div>
					</div>
					<div className="data-cat col-md-4">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Data Protection</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={modelProperties.driveOutageTolerance.formattedLabel}
									data={data?.driveOutageTolerance.value}
									decimals={0}
									unitLabel={data?.driveOutageTolerance.unit}
								/>
								<RowValue
									label={modelProperties.nodeOutageTolerance.formattedLabel}
									data={data?.nodeOutageTolerance.value}
									decimals={0}
									unitLabel={data?.nodeOutageTolerance.unit}
								/>
							</div>
						</div>
					</div>
					<div className="data-cat col-md-4">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Endurance</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={modelProperties.writeVolume.formattedLabel}
									data={data?.writeVolume.value}
									decimals={0}
									unitLabel={data?.writeVolume.unit}
								/>
								<RowValue
									label={modelProperties.clusterOverwriteCadence.formattedLabel}
									data={data?.clusterOverwriteCadence.value}
									decimals={0}
									unitLabel={data?.clusterOverwriteCadence.unit}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row data-row">
					<div className="data-cat col-md-4">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Networking</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={modelProperties.frontEndPorts.formattedLabel}
									data={data?.frontEndPorts.value}
									decimals={0}
									unitLabel={data?.frontEndPorts.unit}
								/>
								{mode === 'existing' && (
									<RowValue
										label={
											existingProperties.frontEndPortsAdded?.formattedLabel
										}
										data={data?.frontEndPortsAdded?.value}
										decimals={0}
										unitLabel={data?.frontEndPorts.unit}
									/>
								)}
								<RowValue
									label={modelProperties.backEndPorts.formattedLabel}
									data={data?.backEndPorts.value}
									decimals={0}
									unitLabel={data?.backEndPorts.unit}
								/>
								{mode === 'existing' && (
									<RowValue
										label={existingProperties.backEndPortsAdded?.formattedLabel}
										data={data?.backEndPortsAdded?.value}
										decimals={0}
										unitLabel={data?.backEndPorts.unit}
									/>
								)}
								<RowValue
									label={modelProperties.frontEndNetworking.formattedLabel}
									data={data?.frontEndNetworking.value}
									decimals={0}
									unitLabel={data?.frontEndNetworking.unit}
								/>
								<RowValue
									label={modelProperties.backEndNetworking.formattedLabel}
									data={data?.backEndNetworking.value}
									decimals={0}
									unitLabel={data?.backEndNetworking.unit}
								/>
							</div>
						</div>
					</div>

					<div className="data-cat col-md-8">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Environmentals</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-6 data-block-item">
								<RowValue
									label={modelProperties.rackSpaceRequired.formattedLabel}
									data={data?.rackSpaceRequired.value}
									decimals={0}
									unitLabel={data?.rackSpaceRequired.unit}
								/>
								{mode === 'existing' && (
									<RowValue
										label={
											existingProperties.rackSpaceRequiredAdded?.formattedLabel
										}
										data={data?.rackSpaceRequiredAdded?.value}
										decimals={0}
										unitLabel={data?.rackSpaceRequired.unit}
									/>
								)}

								<RowValue
									label={modelProperties.height.formattedLabel}
									data={data?.height.value}
									decimals={0}
									unitLabel={data?.height.unit}
								/>
								<RowValue
									label={modelProperties.width.formattedLabel}
									data={data?.width.value}
									decimals={0}
									unitLabel={data?.width.unit}
								/>
								<RowValue
									label={modelProperties.depth.formattedLabel}
									data={data?.depth.value}
									decimals={0}
									unitLabel={data?.depth.unit}
								/>
								<RowValue
									label={modelProperties.weight.formattedLabel}
									data={data?.weight.value}
									decimals={0}
									unitLabel={data?.weight.unit}
								/>
								{mode === 'existing' && (
									<RowValue
										label={existingProperties.weightAdded?.formattedLabel}
										data={data?.weightAdded?.value}
										decimals={0}
										unitLabel={data?.weight.unit}
									/>
								)}
							</div>
							<div className="col-md-6 data-block-item">
								<RowValue
									label={modelProperties.typicalWatts.formattedLabel}
									data={data?.typicalWatts.value}
									decimals={0}
									unitLabel={data?.typicalWatts.unit}
								/>
								{mode === 'existing' && (
									<RowValue
										label={existingProperties.typicalWattsAdded?.formattedLabel}
										data={data?.typicalWattsAdded?.value}
										decimals={0}
										unitLabel={data?.typicalWatts.unit}
									/>
								)}

								<RowValue
									label={modelProperties.typicalAmps110V.formattedLabel}
									data={data?.typicalAmps110V.value}
									decimals={0}
									unitLabel={data?.typicalAmps110V.unit}
								/>
								<RowValue
									label={modelProperties.typicalAmps240V.formattedLabel}
									data={data?.typicalAmps240V.value}
									decimals={0}
									unitLabel={data?.typicalAmps240V.unit}
								/>
								<RowValue
									label={modelProperties.typicalThermalBTU.formattedLabel}
									data={data?.typicalThermalBTU.value}
									decimals={0}
									unitLabel={data?.typicalThermalBTU.unit}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{data &&
				data.modelUid &&
				data.nodeCount.value &&
				typeof data.nodeCount.value === 'number' &&
				data?.stripeWidth.value &&
				data?.dataElementsPerStripe.value &&
				chartsToPrint && (
					<div className="print-modal-charts-wrapper">
						<div
							className={`print-modal-chart-wrapper ${
								!chartsToPrint[0] ? 'no-print' : ''
							}`}
						>
							<div className="print-modal-chart-title-wrapper">
								<div className="print-modal-chart-title">Capacity Planning</div>
								<div className="divider"></div>
								<Checkbox
									onChange={(e) => {
										setChartsToPrint([
											e.target.checked,
											chartsToPrint[1],
											chartsToPrint[2],
											chartsToPrint[3],
										]);
									}}
									checked={chartsToPrint ? chartsToPrint[0] : false}
								/>
							</div>
							{chartsToPrint && chartsToPrint[0] && (
								<div className="print-modal-chart">
									<div className="page-break"></div>

									<CapacityChart {...chartProps} readOnly={true} />
									<div className="page-break"></div>
								</div>
							)}
						</div>

						<div className="page-break"></div>
						<div
							className={`print-modal-chart-wrapper ${
								!chartsToPrint[1] ? 'no-print' : ''
							}`}
						>
							<div className="print-modal-chart-title-wrapper">
								<div className="print-modal-chart-title">Capacity & IOPS</div>
								<div className="divider"></div>
								<Checkbox
									onChange={(e) => {
										setChartsToPrint([
											chartsToPrint[0],
											e.target.checked,
											chartsToPrint[2],
											chartsToPrint[3],
										]);
									}}
									checked={chartsToPrint ? chartsToPrint[1] : false}
								/>
							</div>
							{chartsToPrint && chartsToPrint[1] && (
								<div className="print-modal-chart">
									<div className="page-break"></div>

									<IOPSChart {...chartProps} readOnly={true} />
									<div className="page-break"></div>
								</div>
							)}
						</div>
						<div className="page-break"></div>
						<div
							className={`print-modal-chart-wrapper ${
								!chartsToPrint[2] ? 'no-print' : ''
							}`}
						>
							<div className="print-modal-chart-title-wrapper">
								<div className="print-modal-chart-title">
									Capacity & Throughput
								</div>
								<div className="divider"></div>
								<Checkbox
									onChange={(e) => {
										if (chartsToPrint)
											setChartsToPrint([
												chartsToPrint[0],
												chartsToPrint[1],
												e.target.checked,
												chartsToPrint[3],
											]);
									}}
									checked={chartsToPrint ? chartsToPrint[2] : false}
								/>
							</div>
							{chartsToPrint && chartsToPrint[2] && (
								<div className="print-modal-chart">
									<div className="page-break"></div>
									<ThroughputChart {...chartProps} readOnly={true} />
									<div className="page-break"></div>
								</div>
							)}
						</div>
						{/*{mode !== 'existing' && resilience?.chart?.selected ? (*/}
						{/*	<>*/}
						{/*		<div className="page-break"></div>*/}
						{/*		<div*/}
						{/*			className={`print-modal-chart-wrapper ${*/}
						{/*				!chartsToPrint[3] ? 'no-print' : ''*/}
						{/*			}`}*/}
						{/*		>*/}
						{/*			<div className="print-modal-chart-title-wrapper">*/}
						{/*				<div className="print-modal-chart-title">*/}
						{/*					Capacity & Resilience*/}
						{/*				</div>*/}
						{/*				<div className="divider"></div>*/}
						{/*				<Checkbox*/}
						{/*					onChange={(e) => {*/}
						{/*						if (chartsToPrint)*/}
						{/*							setChartsToPrint([*/}
						{/*								chartsToPrint[0],*/}
						{/*								chartsToPrint[1],*/}
						{/*								chartsToPrint[2],*/}
						{/*								e.target.checked,*/}
						{/*							]);*/}
						{/*					}}*/}
						{/*					checked={chartsToPrint ? chartsToPrint[3] : false}*/}
						{/*				/>*/}
						{/*			</div>*/}
						{/*			{chartsToPrint && chartsToPrint[3] && (*/}
						{/*				<div className="print-modal-chart">*/}
						{/*					<div className="page-break"></div>*/}
						{/*					<ResilienceChart*/}
						{/*						{...chartProps}*/}
						{/*						readOnly={true}*/}
						{/*						driveProtect={*/}
						{/*							resilience?.chart?.defaultSelected?.driveProtect || 0*/}
						{/*						}*/}
						{/*						nodeProtect={*/}
						{/*							resilience?.chart?.defaultSelected?.nodeProtect || 0*/}
						{/*						}*/}
						{/*					/>*/}
						{/*					<div className="page-break"></div>*/}
						{/*				</div>*/}
						{/*			)}*/}
						{/*		</div>*/}
						{/*	</>*/}
						{/*) : null}*/}
					</div>
				)}
		</>
	);

	return data ? clusterLayout : null;
};

export default PrintModalClusterTab;
