import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
	AttributesBlock,
	TableCell,
	ConditionalTableItem,
} from '../../components';

// Slices
import {
	viewSelector,
	setShowExistingModal,
} from '../../slices/calculator/view';
import { filtersSelector } from '../../slices/calculator/filters';
import { globalSelector } from '../../slices/global';
import { modelsSelector } from '../../slices/calculator/models';

// Types
import { ExistingAttributes, ExistingItem } from '../../types';

// Utils
import { lbsToKg, inToCm, TBtoTiB, MBtoMiB } from '../../utils/conversions';

type ColumnProps = {
	cluster: ExistingAttributes;
	existing: ExistingItem[];
};

export type CSSColumnProps = {
	scrollPadding: number;
};

const Column = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	scroll-snap-align: start;
	width: 180px;
	position: sticky;
	z-index: 999;
	${(props: CSSColumnProps) => {
		return css`
			left: ${props.scrollPadding}px;
		`;
	}}
`;

const ExistingFirstRow = styled.div`
	position: sticky;
	top: 0px;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ClusterDescription = styled.span`
	font-weight: 400;
	font-size: 0.9em;
`;

const TableColumn: FunctionComponent<ColumnProps> = (props) => {
	const {
		capacity,
		usableCapacity,
		capacityScalesTo,
		efficiency,
		nodeCount,
		// nodeCountScalesTo,
		performanceClass,
		burstWrite,
		cachedRead,
		iops,
		sustainedWrite,
		singleStreamWrite,
		uncachedRead,
		singleStreamCachedRead,
		singleStreamUncachedRead,
		frontEndPorts,
		frontEndNetworking,
		backEndPorts,
		backEndNetworking,
		writeVolume,
		clusterOverwriteCadence,
		nodeOutageTolerance,
		driveOutageTolerance,
		stripeWidth,
		dataElementsPerStripe,
		rackSpaceRequired,
		height,
		width,
		depth,
		weight,
		typicalWatts,
		typicalAmps110V,
		typicalAmps240V,
		typicalThermalBTU,
	} = props.cluster;
	const [columnHeight, setColumnHeight] = useState<number | null | undefined>(
		null,
	);
	const dispatch = useDispatch();
	const columnRef = useRef<HTMLDivElement>(null);
	const viewSlice = useSelector(viewSelector);
	const { attributes } = useSelector(filtersSelector);
	const { measurements, dataUnits } = useSelector(globalSelector);
	const { models } = useSelector(modelsSelector);
	const { tableMode, showCategory, isInAnimation } = viewSlice;
	const summary = tableMode === 'summary';

	const getStickyPaddingFromDOM = (): number | null | undefined => {
		const filtCol = document.getElementById('filters-column');
		return filtCol?.offsetWidth;
	};
	const [scrollPadding, setScrollPadding] = useState(getStickyPaddingFromDOM());

	useEffect(() => {
		setScrollPadding(getStickyPaddingFromDOM());
	}, [viewSlice.showAttributes]);

	/*
	 ** For the sticky column header and the left sticky filters to work we need to set fixed dimension for the table
	 ** This method will calculate the right dimensions for the column
	 */
	const getColumnHeightFromDOM = (): number | null => {
		if (columnRef.current) {
			// Using the Filter columns as the reference element
			const filtersColumnElement = columnRef.current;

			if (filtersColumnElement && filtersColumnElement.children.length) {
				let res = 0;
				Array.from(filtersColumnElement.children).forEach((item) => {
					if (item instanceof HTMLElement) {
						res += item.offsetHeight;
					}
				});
				return res;
			}
		}
		return null;
	};

	// Re-setting height on Summary / All switch and on Category fold
	useEffect(() => {
		if (!isInAnimation) {
			const calculatedHeight = getColumnHeightFromDOM();
			if (calculatedHeight) setColumnHeight(calculatedHeight);
		}
	}, [summary, showCategory, isInAnimation]);

	/*
	 ** For the sticky column header to work we need to set a fixed height on the column
	 ** We base this height on the filter column height
	 */
	return (
		<Column
			ref={columnRef}
			style={{
				height:
					columnHeight ||
					document.getElementById('filters-column')?.scrollHeight,
			}}
			className={`table-column existing-column`}
			scrollPadding={scrollPadding || 0}
			id="existing-column"
		>
			<ExistingFirstRow
				className="table-first-row existing-first-row"
				onClick={() => dispatch(setShowExistingModal(true))}
			>
				Existing Cluster
				{props.existing && props.existing.length ? (
					<ClusterDescription>
						{props.existing.length === 1
							? models.find(
									(item) => item.modelUid === props.existing[0].modelUid,
							  )?.modelName
							: 'Multiple Models'}
					</ClusterDescription>
				) : null}
			</ExistingFirstRow>
			<AttributesBlock category="capacity">
				<TableCell
					data={
						dataUnits === 'base10' ? usableCapacity : TBtoTiB(usableCapacity)
					}
					unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
					decimals={1}
				/>
				<TableCell data={'-'} existing={true} />
				<TableCell data={`${stripeWidth},${dataElementsPerStripe}`} />
				<ConditionalTableItem
					show={!summary || !!attributes['capacity'].value}
					forcedDisplay={summary && !!attributes['capacity'].value}
				>
					<TableCell
						data={dataUnits === 'base10' ? capacity : TBtoTiB(capacity)}
						unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
						decimals={1}
					/>
				</ConditionalTableItem>
				<TableCell
					data={
						dataUnits === 'base10'
							? capacityScalesTo
							: TBtoTiB(capacityScalesTo)
					}
					unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
					decimals={1}
				/>
				<ConditionalTableItem
					show={!summary || !!attributes['efficiency'].value}
					forcedDisplay={summary && !!attributes['efficiency'].value}
				>
					<TableCell data={efficiency * 100} decimals={1} unitLabel="%" />
				</ConditionalTableItem>
			</AttributesBlock>
			<AttributesBlock category="nodes">
				<TableCell
					data={nodeCount}
					unitLabel="nodes"
				/>
				<TableCell data={'-'} existing={true} />
				{/*<TableCell data={nodeCountScalesTo} unitLabel="nodes" />*/}
			</AttributesBlock>
			<AttributesBlock
				category="cost"
				titleValue={performanceClass}
				unfoldable={true}
			></AttributesBlock>
			<AttributesBlock category="performance">
				<TableCell
					data={dataUnits === 'base10' ? burstWrite : MBtoMiB(burstWrite)}
					unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
				/>
				<TableCell
					data={dataUnits === 'base10' ? cachedRead : MBtoMiB(cachedRead)}
					unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
				/>
				<TableCell data={iops} />
				<ConditionalTableItem
					show={!summary || !!attributes['sustainedWrite'].value}
					forcedDisplay={summary && !!attributes['sustainedWrite'].value}
				>
					<TableCell
						data={
							dataUnits === 'base10' ? sustainedWrite : MBtoMiB(sustainedWrite)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['singleStreamWrite'].value}
					forcedDisplay={summary && !!attributes['singleStreamWrite'].value}
				>
					<TableCell
						data={
							dataUnits === 'base10'
								? singleStreamWrite
								: MBtoMiB(singleStreamWrite)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['uncachedRead'].value}
					forcedDisplay={summary && !!attributes['uncachedRead'].value}
				>
					<TableCell
						data={dataUnits === 'base10' ? uncachedRead : MBtoMiB(uncachedRead)}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['singleStreamCachedRead'].value}
					forcedDisplay={
						summary && !!attributes['singleStreamCachedRead'].value
					}
				>
					<TableCell
						data={
							dataUnits === 'base10'
								? singleStreamCachedRead
								: MBtoMiB(singleStreamCachedRead)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['singleStreamUncachedRead'].value}
					forcedDisplay={
						summary && !!attributes['singleStreamUncachedRead'].value
					}
				>
					<TableCell
						data={
							dataUnits === 'base10'
								? singleStreamUncachedRead
								: MBtoMiB(singleStreamUncachedRead)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					/>
				</ConditionalTableItem>
			</AttributesBlock>
			<AttributesBlock category="dataProtection">
				<TableCell data={driveOutageTolerance} />
				<TableCell data={nodeOutageTolerance} />
				{/* 				<ConditionalTableItem
					show={!summary || !!attributes['stripeWidth'].value}
					forcedDisplay={summary && !!attributes['stripeWidth'].value}
				>
					<TableCell data={stripeWidth} />
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['dataElementsPerStripe'].value}
					forcedDisplay={summary && !!attributes['dataElementsPerStripe'].value}
				>
					<TableCell data={dataElementsPerStripe} />
				</ConditionalTableItem>
 				*/}
			</AttributesBlock>
			<AttributesBlock category="networking">
				<TableCell data={frontEndPorts} unitLabel="ports" />
				<TableCell data={'-'} existing={true} />
				<TableCell data={backEndPorts} unitLabel="ports" />
				<TableCell data={'-'} existing={true} />
				<TableCell data={frontEndNetworking} unitLabel="GbE" />
				<TableCell data={backEndNetworking} unitLabel="GbE" />
			</AttributesBlock>
			<AttributesBlock category="endurance">
				<TableCell
					data={dataUnits === 'base10' ? writeVolume : TBtoTiB(writeVolume)}
					unitLabel={dataUnits === 'base10' ? 'TB/day' : 'TiB/day'}
					decimals={1}
				/>
				<ConditionalTableItem
					show={!summary || !!attributes['clusterOverwriteCadence'].value}
					forcedDisplay={
						summary && !!attributes['clusterOverwriteCadence'].value
					}
				>
					<TableCell data={clusterOverwriteCadence} />
				</ConditionalTableItem>
			</AttributesBlock>
			<AttributesBlock category="environmentals">
				<TableCell data={rackSpaceRequired} unitLabel="U" />
				<TableCell data={'-'} existing={true} />
				<ConditionalTableItem
					show={!summary || !!attributes['height'].value}
					forcedDisplay={summary && !!attributes['height'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? height : inToCm(height).toFixed(1)
						}
						unitLabel={measurements === 'standard' ? 'in' : 'cm'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['width'].value}
					forcedDisplay={summary && !!attributes['width'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? width : inToCm(width).toFixed(1)
						}
						unitLabel={measurements === 'standard' ? 'in' : 'cm'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['depth'].value}
					forcedDisplay={summary && !!attributes['depth'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? depth : inToCm(depth).toFixed(1)
						}
						unitLabel={measurements === 'standard' ? 'in' : 'cm'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['weight'].value}
					forcedDisplay={summary && !!attributes['weight'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? weight : lbsToKg(weight).toFixed(1)
						}
						unitLabel={measurements === 'standard' ? 'lbs' : 'kg'}
					/>
					<TableCell data={'-'} existing={true} />
				</ConditionalTableItem>
				<TableCell data={typicalWatts} unitLabel="W" />
				<TableCell data={'-'} existing={true} />
				<ConditionalTableItem
					show={!summary || !!attributes['typicalAmps110V'].value}
					forcedDisplay={summary && !!attributes['typicalAmps110V'].value}
				>
					<TableCell data={typicalAmps110V} unitLabel="A" />
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['typicalAmps240V'].value}
					forcedDisplay={summary && !!attributes['typicalAmps240V'].value}
				>
					<TableCell data={typicalAmps240V} unitLabel="A" />
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['typicalThermalBTU'].value}
					forcedDisplay={summary && !!attributes['typicalThermalBTU'].value}
				>
					<TableCell data={typicalThermalBTU} unitLabel="BTU/hr" />
				</ConditionalTableItem>
			</AttributesBlock>
		</Column>
	);
};

export default TableColumn;
