import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { useModelsProperties, useURLFilters } from '../../hooks';
import { useDebouncedCallback } from 'use-debounce';

// Slices
import {
	viewSelector,
	setMode,
	setShowExistingModal,
	showChartPage,
} from '../../slices/calculator/view';
import {
	filtersSelector,
	setAttributes,
	clearExistings,
	clearStripeWidth,
	clearNodeOutageTolerance,
} from '../../slices/calculator/filters';
import {
	dataSelector,
	formattedDataSelector,
} from '../../slices/calculator/data';
import {
	dataExistingSelector,
	formattedDataExistingSelector,
} from '../../slices/calculator/dataExisting';

// Components
import Table from './Table';
import DescribeExisting from './DescribeExisting';
import PrintModal from './PrintModal';

// Types
import { FiltersAttributes } from '../../types';

const Panel = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const TableFooter = styled.div`
	height: 110px;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	padding-left: 0px;
`;

const FooterActionsWrapper = styled.div`
	display: flex;
	button {
		margin-bottom: 10px;
	}

	div:first-child {
		display: flex;
		width: 100%;
		justify-content: start;
	}

	div:last-child {
		white-space: nowrap;
	}
`;

const TableHeader = styled.div`
	height: 110px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 17px 0px;
`;

const FlexWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const NewExistingToggle = styled.div`
	button.btn {
		font-size: 15px;
	}
`;

const CapacityInput = styled.input``;

const TablePanel: FunctionComponent = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [copied, setCopied] = useState(false);
	const [showPrintModal, setShowPrintModal] = useState(false);
	const data = useSelector(dataSelector);
	const dataExisting = useSelector(dataExistingSelector);
	const formattedData = useSelector(formattedDataSelector);
	const formattedExistingData = useSelector(formattedDataExistingSelector);
	const view = useSelector(viewSelector);
	const lastRequestDate = new Date(view.lastRequest || 0);
	const filters = useSelector(filtersSelector);
	const attributes = filters.attributes;
	const modelProperties = useModelsProperties().getAllProperties();
	const existingProperties = useModelsProperties().getExistingProperties();
	const { encodeFilters, pushHistory } = useURLFilters();

	const handleReset = () => {
		history.push('');
		window.location.reload();
		// dispatch(resetFilters());
		// if (!models.loading && !models.errors) dispatch(setModels(models.models));
		// dispatch(resetView());
	};

	const [debouncedSet] = useDebouncedCallback(
		(newAttributes: FiltersAttributes) => {
			dispatch(setAttributes(newAttributes));
		},
		1000,
	);

	const handleCapacityChange = (value: number) => {
		const newAttributes = Object.assign({}, attributes, {
			usableCapacity: { value, filter: attributes.usableCapacity.filter },
		});
		pushHistory(
			encodeFilters(Object.assign({}, filters, { attributes: newAttributes })),
			'filters',
		);
		debouncedSet(newAttributes);
	};

	const handleCopy = () => {
		let res = '';
		const isModelSelected = (modelUid: string) => {
			const res = filters.models.filter((i) => i.modelUid === modelUid);
			if (res.length) return res[0].selected;
			return null;
		};
		const selectedModel: string[] = [];

		const copyToClipboard = (text: string) => {
			const el = document.createElement('textarea');
			el.value = text;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		};

		// Using a function to avoid unsafe res ref in loops
		const addToRes = (toAdd: string) => (res += toAdd);

		const addUrlToRes = () => {
			addToRes('Saved Configuration\t');
			addToRes(window.location.href);
			addToRes('\n');
			addToRes('\n');
		};

		if (
			view.mode === 'new' &&
			!data.loading &&
			!data.errors &&
			formattedData.length
		) {
			addUrlToRes();
			addToRes('""\t');
			// First Row
			formattedData.forEach((element) => {
				if (isModelSelected(element.modelUid)) {
					addToRes(`${element.modelName}\t`);
					selectedModel.push(element.modelUid);
				}
			});
			addToRes('\n');

			// Iterate attributes
			for (const key in modelProperties) {
				if (modelProperties.hasOwnProperty(key)) {
					const attrElement = modelProperties[key];
					const firstFormatted = formattedData[0][key];
					let unit = '';
					if (typeof firstFormatted === 'object' && firstFormatted.unit)
						unit = firstFormatted.unit;

					addToRes(
						`${attrElement.formattedLabel} ${
							unit.length ? `(${unit})` : ''
						} \t`,
					);
					formattedData.forEach((element) => {
						if (selectedModel.indexOf(element.modelUid) !== -1) {
							const selectData = element[key];
							if (typeof selectData === 'object') {
								if (
									selectData.value != null &&
									typeof selectData.value === 'number'
								)
									addToRes(`${(selectData.value as number).toFixed(2)}\t`);
								else if (
									selectData.value &&
									typeof selectData.value === 'string'
								)
									addToRes(`${selectData.value}\t`);
								else if (
									selectData.value &&
									Array.isArray(selectData.value) &&
									key === 'encoding' &&
									selectData.value.length >= 2
								)
									addToRes(`${selectData.value[0]},${selectData.value[1]}\t`);
							}
						}
					});
					addToRes('\n');
				}
			}
		} else if (
			view.mode === 'existing' &&
			!dataExisting.loading &&
			!dataExisting.errors &&
			formattedExistingData?.clusters?.length
		) {
			const clusters = formattedExistingData.clusters;
			const existing = formattedExistingData.existing;

			addUrlToRes();
			addToRes('""\t');
			addToRes('Existing\t');
			// First Row
			clusters.forEach((element) => {
				if (isModelSelected(element.modelUid)) {
					addToRes(`w/${element.modelUid}\t`);
					selectedModel.push(element.modelUid);
				}
			});
			addToRes('\n');

			// Iterate attributes
			for (const key in existingProperties) {
				if (existingProperties.hasOwnProperty(key)) {
					const attrElement = existingProperties[key];
					const firstFormatted = clusters[0][key];
					let unit = '';
					if (typeof firstFormatted === 'object' && firstFormatted.unit)
						unit = firstFormatted.unit;

					addToRes(
						`${attrElement.formattedLabel} ${
							unit.length ? `(${unit})` : ''
						} \t`,
					);
					if (existing[key]) {
						const existingValue = existing[key].value;
						if (existingValue != null && typeof existingValue === 'number')
							addToRes(`${(existingValue as number).toFixed(2)}\t`);
						else if (existingValue && typeof existingValue === 'string')
							addToRes(`${existingValue}\t`);
						else if (
							existingValue &&
							Array.isArray(existingValue) &&
							key === 'encoding' &&
							existingValue.length >= 2
						)
							addToRes(`${existingValue[0]},${existingValue[1]}\t`);
					} else {
						addToRes(`-\t`);
					}

					clusters.forEach((element) => {
						if (selectedModel.indexOf(element.modelUid) !== -1) {
							const selectData = element[key];
							if (typeof selectData === 'object') {
								if (
									selectData.value != null &&
									typeof selectData.value === 'number'
								)
									addToRes(`${(selectData.value as number).toFixed(2)}\t`);
								else if (
									selectData.value &&
									typeof selectData.value === 'string'
								)
									addToRes(`${selectData.value}\t`);
								else if (
									selectData.value &&
									Array.isArray(selectData.value) &&
									key === 'encoding' &&
									selectData.value.length >= 2
								)
									addToRes(`${selectData.value[0]},${selectData.value[1]}\t`);
							}
						}
					});
					addToRes('\n');
				}
			}
		}

		copyToClipboard(res);
	};

	const handleExistingClick = () => {
		dispatch(setShowExistingModal(true));
	};

	const handleNewClick = () => {
		dispatch(setMode('new'));
		// Clear existing in URL
		pushHistory(
			encodeFilters(
				Object.assign({}, filters, {
					existings: [],
					stripeWidth: '',
					nodeOutageTolerance: '',
				}),
			),
			'filters',
		);
		dispatch(clearExistings());
		dispatch(clearNodeOutageTolerance());
		dispatch(clearStripeWidth());
	};

	return (
		<Panel id="table-panel" className="table-panel">
			<PrintModal
				show={showPrintModal}
				handleClose={() => {
					setShowPrintModal(false);
				}}
			/>

			<DescribeExisting
				show={view.showExistingModal}
				handleClose={() => dispatch(setShowExistingModal(false))}
			/>
			<TableHeader className="table-header">
				<FlexWrapper>
					<FlexWrapper className="top-capacity" style={{ marginRight: '20px' }}>
						<span>CAPACITY</span>
						<CapacityInput
							type="number"
							className="outlined"
							value={filters?.attributes?.usableCapacity?.value || 0}
							onChange={(e) => handleCapacityChange(parseInt(e.target.value))}
						/>
						<span>TB</span>
					</FlexWrapper>
					<NewExistingToggle
						className={`btn-group new-existing-toggle`}
						role="group"
						aria-label="New Existing Toggle"
					>
						<button
							className={`btn ${view?.mode === 'new' ? 'active' : ''}`}
							onClick={handleNewClick}
						>
							New Cluster
						</button>
						<button
							className={`btn ${view?.mode === 'existing' ? 'active' : ''}`}
							onClick={handleExistingClick}
						>
							Expand/Refresh
						</button>
					</NewExistingToggle>
				</FlexWrapper>
				<FlexWrapper>
					<button className="btn btn-link" onClick={handleReset}>
						<i className="fas fa-undo" />
						&nbsp; Reset All
					</button>
				</FlexWrapper>
			</TableHeader>
			<Table />
			<TableFooter className="table-footer">
				<FooterActionsWrapper>
					<div>
						<button
							className="btn btn-secondary"
							onClick={() => {
								handleCopy();
							}}
						>
							{copied ? 'Copied to clipboard' : 'Copy Table to Clipboard'}
						</button>
					</div>
					<div>
						{!view.selection.length ? (
							<>
								<OverlayTrigger
									placement={'top'}
									overlay={
										<Popover id="category-tooltip">
											<Popover.Content>
												No model selected. Select a model by clicking model name
												in table column above.
											</Popover.Content>
										</Popover>
									}
								>
									<span>
										<button
											className="btn btn-secondary margin-left"
											onClick={() => dispatch(showChartPage())}
											disabled={!view.selection.length}
											style={{ pointerEvents: 'none' }}
										>
											Solution Forecast
										</button>
									</span>
								</OverlayTrigger>
								{/* <OverlayTrigger
									placement={'top'}
									overlay={
										<Popover id="category-tooltip">
											<Popover.Content>
												No model selected. Select a model by clicking model name
												in table column above.
											</Popover.Content>
										</Popover>
									}
								>
									<span>
										<button
											className="btn btn-secondary margin-left"
											onClick={() => setShowPrintModal(true)}
											disabled={!view.selection.length}
											style={{ pointerEvents: 'none' }}
										>
											Generate Report
										</button>
									</span>
								</OverlayTrigger> */}
							</>
						) : (
							<>
								<button
									className="btn btn-secondary margin-left"
									onClick={() => dispatch(showChartPage())}
								>
									Solution Forecast
								</button>
								{/* <button
									className="btn btn-secondary margin-left"
									onClick={() => setShowPrintModal(true)}
									disabled={!view.selection.length}
								>
									Generate Report
								</button> */}
							</>
						)}
					</div>
				</FooterActionsWrapper>
				<span className="bottom-line">
					{view.lastRequest &&
						`
						Information provided for estimation purposes only. Report generated ${lastRequestDate.toUTCString()} - Version ${
							process.env.REACT_APP_SOURCE_VERSION &&
							process.env.REACT_APP_SOURCE_VERSION.substring(0, 7)
						}
						`}
				</span>
			</TableFooter>
		</Panel>
	);
};

export default TablePanel;
