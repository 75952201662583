import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize, useQuery, useURLFilters } from '../../hooks';

// Components
import FiltersPanel from './FiltersPanel';
import TablePanel from './TablePanel';
import { Overlay } from '../../components';
import ErrorAccess from '../error/ErrorAccess';
import { Details } from '../index';

// Slices
import { fetchModels } from '../../slices/calculator/models';
import {
	viewSelector,
	setCategoryFromURL,
	setTableMode,
	toggleFilters,
} from '../../slices/calculator/view';
import { updateFilters } from '../../slices/calculator/filters';
import { modelsSelector } from '../../slices/calculator/models';

const CalculatorWrapper = styled.div`
	display: flex;
	flex-grow: 0;
`;

const Calculator = () => {
	const dispatch = useDispatch();
	const models = useSelector(modelsSelector);
	const view = useSelector(viewSelector);
	const [width, height] = useWindowSize();
	const query = useQuery();

	const { decodeFilters, decodeCategory, decodeRows } = useURLFilters();

	// Open filters panel on desktop mode
	useEffect(() => {
		if (window.innerWidth >= 1200) {
			dispatch(toggleFilters());
		}
	}, [dispatch]);

	// On App Load handling url state query
	useEffect(() => {
		const queryFilter = query.get('filters');
		const queryCat = query.get('cat');
		const queryRows = query.get('rows');
		if (queryFilter) {
			const decoded = decodeFilters(queryFilter);
			if (decoded) dispatch(updateFilters(decoded));
		}
		if (queryCat) {
			const decoded = decodeCategory(queryCat);
			if (decoded) dispatch(setCategoryFromURL(decoded));
		}
		if (queryRows) {
			const decoded = decodeRows(queryRows);
			if (decoded && (decoded === 'summary' || decoded === 'all'))
				dispatch(setTableMode(decoded));
		}
	}, [query, decodeFilters, decodeCategory, decodeRows, dispatch]);

	const getCalculatorWrapperHeight = useCallback(() => {
		const pageHeaderHeight = document.getElementById('page-header')
			?.offsetHeight;
		return height - (pageHeaderHeight || 0);
	}, [height]);
	const [calcHeight, setCalcHeight] = useState(getCalculatorWrapperHeight());

	// On window resize
	useEffect(() => {
		setCalcHeight(getCalculatorWrapperHeight());
	}, [width, height, getCalculatorWrapperHeight]);

	useEffect(() => {
		dispatch(fetchModels());
	}, [dispatch]);

	return (
		<>
			{models.errors ? (
				<ErrorAccess />
			) : (
				<>
					{!models.loading && view.isInChartPage ? (
						<Details />
					) : (
						<CalculatorWrapper style={{ height: `${calcHeight}px` }}>
							<FiltersPanel />
							<TablePanel />
						</CalculatorWrapper>
					)}
				</>
			)}
			{view.showFilters && !view.isInChartPage && <Overlay />}
		</>
	);
};

export default Calculator;
