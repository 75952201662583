import { createSlice } from '@reduxjs/toolkit';
import { fetchExistingData } from '../../api';
import { resIsValid } from '../../api/dataExisting';

// Types
import {
	DataExistingSliceState,
	DataAPIResponse,
	Cluster,
	AppDispatch,
	RootState,
	ExistingAttributes,
	FormattedCluster,
	FormattedExisting,
} from '../../types';

// Selectors & Actions
import { filtersSelector } from './filters';
import { viewSelector } from './view';
import { setLastRequest, toggleInSelection } from './view';

// Actions
import { inToCm, lbsToKg, MBtoMiB, TBtoTiB } from '../../utils/conversions';

export const initialState: DataExistingSliceState = {
	loading: false,
	errors: false,
	existing: null,
	clusters: [],
};

const dataSlice = createSlice({
	name: 'dataExisting',
	initialState,
	reducers: {
		getDataExisting: (state) => {
			state.loading = true;
		},
		getDataExistingSuccess: (
			state,
			{
				payload,
			}: { payload: { clusters: Cluster[]; existing: ExistingAttributes } },
		) => {
			state.clusters = payload.clusters;
			state.existing = payload.existing;
			state.loading = false;
			state.errors = false;
		},
		getDataExistingFailure: (state) => {
			state.loading = false;
			state.errors = true;
		},
		clearError: (state) => {
			state.errors = false;
			return state;
		},
	},
});

export const {
	getDataExisting,
	getDataExistingSuccess,
	getDataExistingFailure,
	clearError,
} = dataSlice.actions;
export const dataExistingSelector = (state: RootState) =>
	state.calculator.dataExisting;
export const formattedDataExistingSelector = (state: RootState) => {
	const res: FormattedCluster[] = [];
	const dataUnits = state.global.dataUnits;
	const measurements = state.global.measurements;

	const formatCluster = (element: Cluster): FormattedCluster => {
		return {
			modelUid: element.modelUid,
			modelName: element.modelName,
			modelNameFull: element.modelNameFull,
			modelNameShort: element.modelNameShort,
			usableCapacity: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10'
						? element.usableCapacity
						: TBtoTiB(element.usableCapacity),
			},
			usableCapacityAdded: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10'
						? element.usableCapacityAdded || 0
						: TBtoTiB(element.usableCapacityAdded || 0),
			},
			encoding: {
				unit: '',
				value: [element.stripeWidth, element.dataElementsPerStripe],
			},
			capacity: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10' ? element.capacity : TBtoTiB(element.capacity),
			},
			capacityScalesTo: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10'
						? element.capacityScalesTo
						: TBtoTiB(element.capacityScalesTo),
			},
			efficiency: {
				unit: '%',
				value: element.efficiency * 100,
			},
			nodeCount: {
				unit: 'nodes',
				value: element.nodeCount,
			},
			nodeAdded: {
				unit: 'nodes',
				value: element.nodeAdded || 0,
			},
			nodeCountScalesTo: {
				unit: 'nodes',
				value: element.nodeCountScalesTo,
			},
			performanceClass: {
				unit: '',
				value: element.performanceClass,
			},
			burstWrite: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.burstWrite
						: MBtoMiB(element.burstWrite),
			},
			cachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.cachedRead
						: MBtoMiB(element.cachedRead),
			},
			iops: {
				unit: '',
				value: element.iops,
			},
			sustainedWrite: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.sustainedWrite
						: MBtoMiB(element.sustainedWrite),
			},
			singleStreamWrite: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.singleStreamWrite
						: MBtoMiB(element.singleStreamWrite),
			},
			uncachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.uncachedRead
						: MBtoMiB(element.uncachedRead),
			},
			singleStreamCachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.singleStreamCachedRead
						: MBtoMiB(element.singleStreamCachedRead),
			},
			singleStreamUncachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.singleStreamUncachedRead
						: MBtoMiB(element.singleStreamUncachedRead),
			},
			driveOutageTolerance: {
				unit: '',
				value: element.driveOutageTolerance,
			},
			nodeOutageTolerance: {
				unit: '',
				value: element.nodeOutageTolerance,
			},
			frontEndPorts: {
				unit: 'ports',
				value: element.frontEndPorts,
			},
			frontEndPortsAdded: {
				unit: 'ports',
				value: element.frontEndPortsAdded || 0,
			},
			backEndPorts: {
				unit: 'ports',
				value: element.backEndPorts,
			},
			backEndPortsAdded: {
				unit: 'ports',
				value: element.backEndPortsAdded || 0,
			},
			frontEndNetworking: {
				unit: 'GbE',
				value: element.frontEndNetworking,
			},
			backEndNetworking: {
				unit: 'GbE',
				value: element.backEndNetworking,
			},
			writeVolume: {
				unit: dataUnits === 'base10' ? 'TB/day' : 'TiB/day',
				value:
					dataUnits === 'base10'
						? element.writeVolume
						: TBtoTiB(element.writeVolume),
			},
			clusterOverwriteCadence: {
				unit: '',
				value: element.clusterOverwriteCadence,
			},
			rackSpaceRequired: {
				unit: 'U',
				value: element.rackSpaceRequired,
			},
			rackSpaceRequiredAdded: {
				unit: 'U',
				value: element.rackSpaceRequiredAdded || 0,
			},
			height: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.height
						: inToCm(element.height).toFixed(1),
			},
			width: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.width
						: inToCm(element.width).toFixed(1),
			},
			depth: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.depth
						: inToCm(element.depth).toFixed(1),
			},
			weight: {
				unit: measurements === 'standard' ? 'lbs' : 'kg',
				value:
					measurements === 'standard'
						? element.weight
						: lbsToKg(element.weight).toFixed(1),
			},
			weightAdded: {
				unit: measurements === 'standard' ? 'lbs' : 'kg',
				value:
					measurements === 'standard'
						? element.weightAdded || 0
						: lbsToKg(element.weightAdded || 0).toFixed(1),
			},
			typicalWatts: {
				unit: 'W',
				value: element.typicalWatts,
			},
			typicalWattsAdded: {
				unit: 'W',
				value: element.typicalWattsAdded || 0,
			},
			typicalAmps110V: {
				unit: 'A',
				value: element.typicalAmps110V,
			},
			typicalAmps240V: {
				unit: 'A',
				value: element.typicalAmps240V,
			},
			typicalThermalBTU: {
				unit: 'BTU/hr',
				value: element.typicalThermalBTU,
			},
			stripeWidth: {
				unit: '',
				value: element.stripeWidth,
			},
			dataElementsPerStripe: {
				unit: '',
				value: element.dataElementsPerStripe,
			},
		};
	};

	const formatExisting = (element: ExistingAttributes): FormattedExisting => {
		return {
			usableCapacity: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10'
						? element.usableCapacity
						: TBtoTiB(element.usableCapacity),
			},
			encoding: {
				unit: '',
				value: [element.stripeWidth, element.dataElementsPerStripe],
			},
			capacity: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10' ? element.capacity : TBtoTiB(element.capacity),
			},
			capacityScalesTo: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10'
						? element.capacityScalesTo
						: TBtoTiB(element.capacityScalesTo),
			},
			efficiency: {
				unit: '%',
				value: element.efficiency * 100,
			},
			nodeCount: {
				unit: 'nodes',
				value: element.nodeCount,
			},
			nodeCountScalesTo: {
				unit: 'nodes',
				value: element.nodeCountScalesTo,
			},
			performanceClass: {
				unit: '',
				value: element.performanceClass,
			},
			burstWrite: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.burstWrite
						: MBtoMiB(element.burstWrite),
			},
			cachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.cachedRead
						: MBtoMiB(element.cachedRead),
			},
			iops: {
				unit: '',
				value: element.iops,
			},
			sustainedWrite: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.sustainedWrite
						: MBtoMiB(element.sustainedWrite),
			},
			singleStreamWrite: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.singleStreamWrite
						: MBtoMiB(element.singleStreamWrite),
			},
			uncachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.uncachedRead
						: MBtoMiB(element.uncachedRead),
			},
			singleStreamCachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.singleStreamCachedRead
						: MBtoMiB(element.singleStreamCachedRead),
			},
			singleStreamUncachedRead: {
				unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
				value:
					dataUnits === 'base10'
						? element.singleStreamUncachedRead
						: MBtoMiB(element.singleStreamUncachedRead),
			},
			driveOutageTolerance: {
				unit: '',
				value: element.driveOutageTolerance,
			},
			nodeOutageTolerance: {
				unit: '',
				value: element.nodeOutageTolerance,
			},
			frontEndPorts: {
				unit: 'ports',
				value: element.frontEndPorts,
			},
			backEndPorts: {
				unit: 'ports',
				value: element.backEndPorts,
			},
			frontEndNetworking: {
				unit: 'GbE',
				value: element.frontEndNetworking,
			},
			backEndNetworking: {
				unit: 'GbE',
				value: element.backEndNetworking,
			},
			writeVolume: {
				unit: dataUnits === 'base10' ? 'TB/day' : 'TiB/day',
				value:
					dataUnits === 'base10'
						? element.writeVolume
						: TBtoTiB(element.writeVolume),
			},
			clusterOverwriteCadence: {
				unit: '',
				value: element.clusterOverwriteCadence,
			},
			rackSpaceRequired: {
				unit: 'U',
				value: element.rackSpaceRequired,
			},
			height: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.height
						: inToCm(element.height).toFixed(1),
			},
			width: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.width
						: inToCm(element.width).toFixed(1),
			},
			depth: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.depth
						: inToCm(element.depth).toFixed(1),
			},
			weight: {
				unit: measurements === 'standard' ? 'lbs' : 'kg',
				value:
					measurements === 'standard'
						? element.weight
						: lbsToKg(element.weight).toFixed(1),
			},
			typicalWatts: {
				unit: 'W',
				value: element.typicalWatts,
			},
			typicalAmps110V: {
				unit: 'A',
				value: element.typicalAmps110V,
			},
			typicalAmps240V: {
				unit: 'A',
				value: element.typicalAmps240V,
			},
			typicalThermalBTU: {
				unit: 'BTU/hr',
				value: element.typicalThermalBTU,
			},
			stripeWidth: {
				unit: '',
				value: element.stripeWidth,
			},
			dataElementsPerStripe: {
				unit: '',
				value: element.dataElementsPerStripe,
			},
		};
	};

	state?.calculator?.dataExisting?.clusters?.forEach((element) => {
		res.push(formatCluster(element));
	});

	if (state?.calculator?.dataExisting?.existing)
		return {
			clusters: res,
			existing: formatExisting(state?.calculator?.dataExisting?.existing),
		};
};

export default dataSlice.reducer;

export function fetchExisting(
	recommendedOnly: boolean,
	allowTranscoding: boolean,
) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		let filters = filtersSelector(getState());
		const view = viewSelector(getState());

		// Clearing invalid existings
		const filtered = filters.existings.filter((item) => item.nodeCount);
		filters = Object.assign({}, filters, {
			existings: filtered,
		});
		dispatch(getDataExisting());

		try {
			const data: DataAPIResponse = await fetchExistingData(
				filters,
				recommendedOnly,
				allowTranscoding,
			);
			if (resIsValid(data)) {
				dispatch(setLastRequest(Date.now()));
				dispatch(getDataExistingSuccess(data));
				if (!view.selection.length && data.clusters.length) {
					dispatch(toggleInSelection(data.clusters[0].modelUid));
				}
			} else {
				throw new Error('Data Existing Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getDataExistingFailure());
		}
	};
}
