import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
	FiltersSliceState,
	URLFilters,
	ShowCategory,
	ModelProperties,
	NodeDetailProperties,
} from '../types';
import pictureGetter from './pictureGetter';

export const useModelPicture = pictureGetter;

// Utility hook that returns window size on resize
export function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}

export function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function useURLFilters(): {
	encodeFilters: (filters: FiltersSliceState) => string;
	pushHistory: (encoded: string, label: string) => void;
	decodeFilters: (encoded: string) => FiltersSliceState | undefined;
	encodeSelectionState: (selection: string[]) => string;
	decodeSelectionState: (encoded: string) => string[] | undefined;
	encodeCategory: (categories: ShowCategory, category: string) => string;
	decodeCategory: (encoded: string) => number[] | undefined;
	encodeRows: (rows: string) => string;
	decodeRows: (encoded: string) => string | undefined;
} {
	const encodeFilters = (filters: FiltersSliceState) => {
		// Deep cloning to avoid mutating ref
		const cloned = JSON.parse(JSON.stringify(filters));
		delete cloned.models;
		const encoded = btoa(JSON.stringify(cloned));
		return encoded;
	};
	const decodeFilters = (encoded: string) => {
		try {
			const decoded: URLFilters = JSON.parse(atob(encoded));
			const newFilters: FiltersSliceState = {
				...decoded,
				models: [],
			};
			return newFilters;
		} catch (error) {
			console.log(error);
		}
	};
	const encodeSelectionState = (selection: string[]) => {
		const encoded = btoa(JSON.stringify(selection));
		return encoded;
	};
	const decodeSelectionState = (encoded: string) => {
		try {
			const decoded: string[] = JSON.parse(atob(encoded));
			return decoded;
		} catch (error) {
			console.log(error);
		}
	};
	const encodeCategory = (categories: ShowCategory, category: string) => {
		let res: number[] = [];
		for (let [key, value] of Object.entries(categories)) {
			key === category ? res.push(value ? 1 : 0) : res.push(value ? 0 : 1);
		}
		return btoa(JSON.stringify(res));
	};
	const decodeCategory = (encoded: string) => {
		try {
			const decoded: number[] = JSON.parse(atob(encoded));
			return decoded;
		} catch (error) {
			console.log(error);
		}
	};

	const encodeRows = (rows: string) => {
		return btoa(JSON.stringify(rows));
	};
	const decodeRows = (encoded: string) => {
		try {
			const decoded: string = JSON.parse(atob(encoded));
			return decoded;
		} catch (error) {
			console.log(error);
		}
	};

	const query = useQuery();
	const history = useHistory();
	const pushHistory = (encoded: string, label: string) => {
		query.delete('code');
		query.delete('state');
		query.set(label, encoded);
		history.push(`?${query.toString()}`);
	};
	return {
		encodeFilters,
		pushHistory,
		decodeFilters,
		encodeSelectionState,
		decodeSelectionState,
		encodeCategory,
		decodeCategory,
		encodeRows,
		decodeRows,
	};
}

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export function useModelsProperties() {
	const data: ModelProperties = {
		usableCapacity: { formattedLabel: 'Capacity (Usable)' },
		encoding: { formattedLabel: 'Encoding' },
		capacity: { formattedLabel: 'Capacity (Raw)' },
		capacityScalesTo: { formattedLabel: 'Config Can Scale to' },
		efficiency: { formattedLabel: 'Efficiency' },
		nodeCount: { formattedLabel: 'Nodes in Cluster' },
		nodeCountScalesTo: { formattedLabel: 'Need to transcode above' },
		encodingScalesTo: { formattedLabel: 'Encoding can scale to' },
		performanceClass: { formattedLabel: 'Performance Class' },
		burstWrite: { formattedLabel: 'Burst Write' },
		cachedRead: { formattedLabel: 'Cached Read' },
		iops: { formattedLabel: 'IOPS' },
		sustainedWrite: { formattedLabel: 'Sustained Write' },
		singleStreamWrite: { formattedLabel: 'Single Stream Write' },
		uncachedRead: { formattedLabel: 'Uncached Read' },
		singleStreamCachedRead: { formattedLabel: 'SS Cached Read' },
		singleStreamUncachedRead: { formattedLabel: 'SS Uncached Read' },
		frontEndPorts: { formattedLabel: 'Front-end Ports' },
		backEndPorts: { formattedLabel: 'Back-end Ports' },
		frontEndNetworking: { formattedLabel: 'Front-end Networking' },
		backEndNetworking: { formattedLabel: 'Back-end Networking' },
		writeVolume: { formattedLabel: 'Write Volume (Max)' },
		clusterOverwriteCadence: {
			formattedLabel: 'Cluster Overwrite Cadence (Max)',
		},
		nodeOutageTolerance: { formattedLabel: 'Node Outage Tolerance' },
		driveOutageTolerance: { formattedLabel: 'Drive Outage Tolerance' },
		stripeWidth: { formattedLabel: 'Stripe Width' },
		dataElementsPerStripe: { formattedLabel: 'Data Elements per Stripe' },
		rackSpaceRequired: { formattedLabel: 'Rack Space Required' },
		height: { formattedLabel: 'Height (Node)' },
		width: { formattedLabel: 'Width (Node)' },
		depth: { formattedLabel: 'Depth (Node)' },
		weight: { formattedLabel: 'Weight (Total)' },
		typicalWatts: { formattedLabel: 'Typical Watts (Total)' },
		typicalAmps110V: { formattedLabel: 'Typical Amps @110/115V (Total)' },
		typicalAmps240V: { formattedLabel: 'Typical Amps @240V (Total)' },
		typicalThermalBTU: { formattedLabel: 'Typical Thermal BTU/hr (Total)' },
	};

	const dataExisting: ModelProperties = {
		usableCapacity: { formattedLabel: 'Capacity (Usable)' },
		usableCapacityAdded: { formattedLabel: 'Added Capacity' },
		encoding: { formattedLabel: 'Encoding' },
		capacity: { formattedLabel: 'Capacity (Raw)' },
		capacityScalesTo: { formattedLabel: 'Config Can Scale to' },
		efficiency: { formattedLabel: 'Efficiency' },
		nodeCount: { formattedLabel: 'Nodes in Cluster' },
		nodeAdded: { formattedLabel: 'Added Nodes' },
		nodeCountScalesTo: { formattedLabel: 'Config Can Scale to' },
		performanceClass: { formattedLabel: 'Performance Class' },
		burstWrite: { formattedLabel: 'Burst Write' },
		cachedRead: { formattedLabel: 'Cached Read' },
		iops: { formattedLabel: 'IOPS' },
		sustainedWrite: { formattedLabel: 'Sustained Write' },
		singleStreamWrite: { formattedLabel: 'Single Stream Write' },
		uncachedRead: { formattedLabel: 'Uncached Read' },
		singleStreamCachedRead: { formattedLabel: 'SS Cached Read' },
		singleStreamUncachedRead: { formattedLabel: 'SS Uncached Read' },
		frontEndPorts: { formattedLabel: 'Front-end Ports' },
		frontEndPortsAdded: { formattedLabel: 'Added Ports' },
		backEndPorts: { formattedLabel: 'Back-end Ports' },
		backEndPortsAdded: { formattedLabel: 'Added Ports' },
		frontEndNetworking: { formattedLabel: 'Front-end Networking' },
		backEndNetworking: { formattedLabel: 'Back-end Networking' },
		writeVolume: { formattedLabel: 'Write Volume (Max)' },
		clusterOverwriteCadence: {
			formattedLabel: 'Cluster Overwrite Cadence (Max)',
		},
		nodeOutageTolerance: { formattedLabel: 'Node Outage Tolerance' },
		driveOutageTolerance: { formattedLabel: 'Drive Outage Tolerance' },
		stripeWidth: { formattedLabel: 'Stripe Width' },
		dataElementsPerStripe: { formattedLabel: 'Data Elements per Stripe' },
		rackSpaceRequired: { formattedLabel: 'Rack Space Required' },
		rackSpaceRequiredAdded: {
			formattedLabel: 'Additional Rack Space required',
		},
		height: { formattedLabel: 'Height (Node)' },
		width: { formattedLabel: 'Width (Node)' },
		depth: { formattedLabel: 'Depth (Node)' },
		weight: { formattedLabel: 'Weight (Total)' },
		weightAdded: { formattedLabel: 'Additional Weight' },
		typicalWatts: { formattedLabel: 'Typical Watts (Total)' },
		typicalWattsAdded: { formattedLabel: 'Additional Power required' },
		typicalAmps110V: { formattedLabel: 'Typical Amps @110/115V (Total)' },
		typicalAmps240V: { formattedLabel: 'Typical Amps @240V (Total)' },
		typicalThermalBTU: { formattedLabel: 'Typical Thermal BTU/hr (Total)' },
	};

	const details: NodeDetailProperties = {
		performanceClass: { formattedLabel: 'Performance Class' },
		rawCapacity: { formattedLabel: 'Capacity (Raw)' },
		hddCount: { formattedLabel: 'HDDs' },
		hddSize: { formattedLabel: 'HDD capacity' },
		ssdCount: { formattedLabel: 'SSDs' },
		ssdSize: { formattedLabel: 'SSD capacity' },
		cpu: { formattedLabel: 'CPU' },
		memory: { formattedLabel: 'Memory' },
		rackUnits: { formattedLabel: 'Rack units' },
		weight: { formattedLabel: 'Weight' },
		height: { formattedLabel: 'Height' },
		width: { formattedLabel: 'Width' },
		depth: { formattedLabel: 'Depth' },
		frontEndPorts: { formattedLabel: 'Front-end Ports' },
		backEndPorts: { formattedLabel: 'Back-end Ports' },
		networkingSpeed: { formattedLabel: 'Networking Speed' },
		networkConnector: { formattedLabel: 'Network Connector' },
		managementNetwork: { formattedLabel: 'Management Network' },
		powerSupply: { formattedLabel: 'Power Supply' },
		powerConnector: { formattedLabel: 'Power Connector' },
		powerRequirements: { formattedLabel: 'Power Requirements' },
		typicalPowerConsumption: { formattedLabel: 'Typical Power Consumption' },
		typicalThermalRating: { formattedLabel: 'Typical Thermal Rating' },
		maximumPowerConsumption: { formattedLabel: 'Maximum Power Consumption' },
		maximumThermalRating: { formattedLabel: 'Maximum Thermal Rating' },
		operatingTemperatureF: { formattedLabel: 'Operating Temperature' },
		nonOperatingTemperatureF: { formattedLabel: 'Non-operating Temperature' },
		operatingHumidity: {
			formattedLabel: 'Operating Humidity',
		},
		nonOperatingHumidity: {
			formattedLabel: 'Non-operating Humidity',
		},
		maximumOperatingAltitude: { formattedLabel: 'Maximum Operating altitude' },
	};

	const getAllProperties = () => data;
	const getExistingProperties = () => dataExisting;
	const getDetailsProperties = () => details;

	const getProperty = (name: string) => dataExisting[name] || null;

	return {
		getAllProperties,
		getProperty,
		getExistingProperties,
		getDetailsProperties,
	};
}
